/** 插屏 */
const Inter: IAdConfig[] = [
  {
    space_src: "baidujs",
    space_type: "inters",
    space_id: "u6939658",
    space_js: "//cwflhpb.chenglsw.com/source/yt_sb/resource/lb_s_qv.js"
  }
];

/** 大图信息流1 */
const NativeLg1: IAdConfig[] = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6939659",
    space_js: "//cwflhpb.chenglsw.com/common/av_udndu_s/d.js"
  }
];

/** 小图信息流1 */
const NativeSm1: IAdConfig[] = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6939660",
    space_js: "//cwflhpb.chenglsw.com/production/r/production/ml/static/ueu/lls.js"
  }
];

/** 大图信息流2 */
const NativeLg2: IAdConfig[] = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6939661",
    space_js: "//cwflhpb.chenglsw.com/site/s/common/n/resource/mv/openjs/fvmm/w.js"
  }
];

export default {
  // 首页-插屏
  HomeInter: Inter,
  // 首页信息流
  HomeNative: NativeLg1,
  HomeNative2: NativeLg2,
  // 首页banner
  HomeBanner: NativeSm1,

  // 节假日页信息流
  HolidaysListNative: NativeLg1,
  // 节假日页Banner
  HolidaysListBanner: NativeSm1,
  HolidaysListInter: Inter,

  // 黄历详情页信息流
  HuangliDetailNative: NativeLg1,
  // 黄历详情页Banner
  HuangliDetailBanner: NativeSm1,
  HuangliDetailInter: Inter,

  // 历史列表页信息流
  HistoryListNative: NativeLg1,
  // 历史列表页Banner
  HistoryListBanner: NativeSm1,
  HistoryListInter: Inter,

  // 历史详情页信息流
  HistoryDetailNative: NativeLg1,
  // 历史详情页Banner
  HistoryDetailBanner: NativeSm1,
  // 历史详情页-插屏
  HistoryDetailInter: Inter,

  // 天气详情页信息流
  WeatherDetailNative: NativeLg1,
  // 天气详情页Banner
  WeatherDetailBanner: NativeSm1,
  WeatherDetailInter: Inter,
  
  // 话题详情页-插屏 *
  TopicDetailInter: Inter,
  // 话题详情页信息流
  TopicDetailNative: NativeLg1,
  // 话题详情页Banner
  TopicDetailBanner: NativeSm1,
  // 话题结果弹窗
  TopicResultDialog: NativeLg2
};
